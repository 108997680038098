@use "../../style/variables" as *;
@use "../../style/mixins";

.female {
    background-color: $bodyBcg;
}

.female__container {
    grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
    row-gap: 2rem;

    @include mixins.breakpoint-up(medium) {
        column-gap: 1rem;
    }

    .boot {
        position: relative;
        height: 20rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        padding: 2rem 1rem;
        background-color: $lightClr;
        border-radius: $radius;
        box-shadow: $shadowOne;
        transition: .3s;

        &:hover {
            transform: translateY(-.5rem);
        }

        &__img {
            position: absolute;
            top: 2.5rem;
            width: 10rem;
            transform: $rotateImg;
            filter: drop-shadow(0 12px 8px rgba(0,0,0, .2));        
        }

        &__name, &__price {
            font-size: $fontMd;
            font-weight: 500;
        }

        &__name {
            margin-bottom: $mb-1;
            text-transform: capitalize;
        }

        &__price {
            margin-bottom: $mb-3;
        }
    }
}