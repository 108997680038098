@use "../../style/mixins";
@use "../../style/variables" as *;

.footer {
    background-color: $bodyBcg;
}

.footer__container {
    grid-template-columns: 100%;

    @include mixins.breakpoint-up(medium){
        grid-template-columns: repeat(4,1fr);
        justify-items: center;
    }
    
    .footer__logo {
        color: $darkClr;
        font-weight: 900;
        text-transform: uppercase;
        line-height: 2;
    }

    .footer__box {
        margin-bottom: $mb-3;
    }

    .footer__title {
        font-size: $fontMd;
        margin-bottom: $mb-1;
        text-transform: uppercase;
    }

    .footer__link {
        display: block;
        width: max-content;
        color: $darkClr;
        font-weight: 500;
        margin-bottom: $mb-2;
        text-transform: capitalize;

        &:hover {
            text-decoration: underline;
        }
    }

    .footer__social {
        display: inline-block;
        padding: .4rem;
        border-radius: 50%;
        margin-right: $mb-1;
        box-shadow: $shadowTwo;

        & img {
            filter: drop-shadow($shadowTwo);
        }
    }
}

.footer__copy {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
}