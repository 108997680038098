@use "../../style/mixins";
@use "../../style/variables" as *;

.shop {
    background-color: $bodyBcg;

    .shop__container {
        grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
        row-gap: 1rem;
        column-gap: 3rem;
    
        @include mixins.breakpoint-up(medium) {
            column-gap: 2rem;
        }
    
        .shop__boot {
            position: relative;
            height: 15rem;
            width: 10rem;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            padding: 2rem 1rem;
            background-color: $lightClr;
            border-radius: $radius;
            box-shadow: $shadowOne;
            transition: .3s;
    
            &:hover {
                transform: translateY(-.5rem);
            }
    
            &-img {
                position: absolute;
                top: 2.5rem;
                width: 8rem;
                transform: $rotateImg;
                filter: drop-shadow(0 12px 8px rgba(0,0,0, .2));        
            }
    
            &-name, &-price {
                font-size: $fontMd;
                font-weight: 500;
            }
    
            &-name {
                margin-bottom: $mb-1;
                text-transform: capitalize;
            }
    
            &-price {
                margin-bottom: $mb-1;
            }
        }
    }
}