@use "../../style/mixins";
@use "../../style/variables" as *;

.new {
    background-color: $bodyBcg;
}

.new__container {
    row-gap: 2rem;

    @include mixins.breakpoint-up(large) {
        grid-template-columns: max-content 1fr;
    }

    .new__men {
       display: flex;
       flex-direction: column;
       justify-content: center;
       padding: 2rem;
       align-items: center;
       background-color: $lightClr;
       border-radius: $radius;
       box-shadow: $shadowOne;

       @include mixins.breakpoint-up(small) {
        align-items: center;
       }

       &-img {
        width: 17rem;
        margin-bottom: $mb-3;

        @include mixins.breakpoint-up(medium) {
            width: 14rem;
        }
       }

       .new__title {
        margin-bottom: .25rem;
        text-align: center;
        text-transform: capitalize;
       }

       .new__price {
        display: block;
        margin-bottom: $mb-3;
        font-weight: 500;
        text-transform: capitalize;
       }

       .new__button {
        display: inline-flex;
        align-items: center;
        background-color: $softGreenClr;
        padding: 1rem 2rem;
        border-radius: $radius;
        transition: .3s;
        box-shadow: $shadowTwo;
        font-weight: 700;
        color: $darkClr;

        &:hover img {
            transform: translateX(.25rem);
        }
    
        & img {
            transition: .3s;
            margin-left: $mb-1;
        }
       }
    }

    .new__boot {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(13.75rem, 1fr));
        gap: 1.5rem;

        .new__boot-card {
            position: relative;
            padding: 3.5rem 1.5rem;
            background-color: $lightClr;
            border-radius: $radius;
            overflow: hidden;
            display: flex;
            justify-content: center;
            box-shadow: $shadowOne;

            .new__boot-img {
                width: 13.75rem;

                @include mixins.breakpoint-up(medium) {
                    width: 10rem;
                }
            }

            .new__boot-overlay {
                position: absolute;
                left: 0;
                bottom: -100%;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;
                background-color: rgba($darkClr, .3);
                transition: .5s;
            }

            &:hover .new__boot-overlay {
                bottom: 0;
            }

            .overlay__title, .overlay__price {
                color: $lightClr;
                text-align: center;
                text-transform: capitalize;
            }

            .overlay__price {
                font-weight: 500;
            }
        }
    }
}