@use "../../style/mixins";
@use "../../style/variables" as *;

.cart {
    position: fixed;
    background-color: $whiteClr;
    z-index: 100;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding: 3.5rem 0;
    transition: .5s;
    transform: scale(0) rotate(720deg);
    opacity: 0;
    transition-delay: .2s;

    @include mixins.breakpoint-up(medium){
        width: 26.25rem;
        box-shadow: $shadowOne;
    }

    .cart__close {
        position: absolute;
        top: 1.25rem;
        left: .9rem;
        cursor: pointer;
    }

    .cart__title-center {
        text-align: center;
        margin-bottom: $mb-4;
        text-transform: uppercase;
        background: linear-gradient(170deg, #fac002 27.16%, #2d03fd 97.86%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

    .cart__container {
        justify-items: center;
        row-gap: 1.5rem;
    }

    .cart__card {
        display: flex;
        align-items: center;
        column-gap: 1rem;
    }

    .cart__box {
        padding: .75rem 1.25rem;
        border: 1px solid $darkClr;

        .cart__img {
            width: 6rem;
        }
    }

    .cart__title {
        margin-bottom: $mb-1;
    }

    .cart__price {
        display: block;
        font-size: $fontSm;
        margin-bottom: $mb-1;
    }

    .cart__amount,
    .cart__amount-content {
        display: flex;
        align-items: center;
    }

    .cart__amount {
        column-gap: 3rem;

        &-content {
            column-gap: 1rem;
        }

        &-box {
            display: inline-flex;
            padding: .25rem;
            background-color: $whiteClr;
            border: 1px solid $darkClr;
            cursor: pointer;
        }

        &-trash {
            cursor: pointer;
        }
    }

    .cart__prices {
        margin-top: 6rem;
        display: flex;
        justify-content: space-around;

        &-item,
        &-total {
            color: $darkClr;
        }

        &-item {
            font-size: $fontSm;
        }

        &-total {
            color: $fontMd;
            font-weight: 500;
        }
    }
}

.show-cart {
    transform: scale(1) rotate(0deg);
    opacity: 1;
    transition-duration: .5s;
}