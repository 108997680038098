@use "../../style/variables" as *;
@use "../../style/mixins";

.home {
    overflow: hidden;
    letter-spacing: 1px;
    padding: 1rem 0;

    &__container {
        height: calc(100vh - $headerHight);
        padding: 2rem 0;

        @include mixins.breakpoint-up(medium){
            height: 100vh;
            grid-template-columns: repeat(2, 1fr);
            justify-content: space-between;
            align-items: center;
            column-gap: 1rem;
            padding: 0;
        }

        .home__boot {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            @include mixins.breakpoint-up(medium) {
                order: 1;
                row-gap: 0;
            }

            .home__overlay {
                width: 13rem;
                height: 13rem;
                background-color: $bodyBcg;
                border-radius: 50%;
                box-shadow: $shadowOne;
                box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;

                @include mixins.breakpoint-up(medium) {
                    width: 25rem;
                    height: 25rem;
                }
            }

            .home__img {
                position: absolute;
                width: 13rem;
                max-width: initial;
                bottom: 5rem;
                transform: $rotateImg;
                filter: drop-shadow(rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px);

                @include mixins.breakpoint-up(medium) {
                    width: 27rem;
                    right: 4rem;
                }
            }
        }

        .home__data {
            text-transform: capitalize;
            color: $darkClr;

            & h1 {
                font-weight: 900;
                line-height: 110%;
            }

            & h1:nth-child(3){   
                background: linear-gradient(170deg, #fac002 27.16%, #2d03fd 97.86%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;

                @include mixins.breakpoint-up(medium) {
                    line-height: 150%;
                }
            }

            .home__new {
                display: block;
                font-weight: 700;
            }
    
            .home__description {
                margin: 1rem 0;
                font-weight: 700;
            }
        }
    }
}